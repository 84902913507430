import select2 from 'select2';

$(document).on('turbolinks:load', function () {
  select2();

  $('.select2').each(function (i, item) {
    $(item).select2({
      tags: $(item).hasClass('select2_tags')
    });
  });
});
