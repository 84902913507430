/**
 * modoui_image_upload v1.0
 * Copyright Modo Labs 2018
 * by: deb penny and charley bandes
 *
 * To use:
 * initUppyFileControls();
 *
 * Description: jquery+js handles the instantiation of the Uppy plugin file upload controls
 *
 **/

(function ($) {
  'use strict';

  let options = {};

  const methods = {
    init
  };

  // -----------------------------------------------------------------------------
  // instance definitions
  // -----------------------------------------------------------------------------

  $.fn.initUppySplashFileControls = function (opts) {
    options = opts;
    methods.init.apply();
  };

  function init () {
    $('.splash_uploader_component .file_drop_area').each(function (i, element) {
      const $element = $(element);
      initEventHandlers($element);
    });
  }

  function initEventHandlers ($element) {
    $element.on('upload-success.modoui', function (event) {
      const previewSplash = $('#' + $element.data('uppy-splash-preview'));

      if (previewSplash.length !== undefined) {
        if (previewSplash.is('img')) {
          previewSplash.attr('src', options.bucket + event.file.storage + '/' + event.file.id);
        } else {
          previewSplash.css('background-image', 'url(' + options.bucket + event.file.storage + '/' + event.file.id + ')');
        }
      }
    });

    $element.on('remove-pressed.modoui', function () {
      const $preview_area = $('#' + $element.data('uppy-splash-preview'));
      if ($preview_area.is('img')) {
        $preview_area.removeAttr('src');
      } else {
        $preview_area.css('background-image', 'none');
      }
    });
  }
})($);
