// Turbolinks caches pages in order to improve perceived performance.
// When a page is visited, a copy of the page is cached for later use.
// If a user navigates to a page that had been previously cached, the cached
// content will be displayed immediately, while the real page is fetched in the
// background, and replaces the cached content when ready.
//
// In cases where a page has a flash banner, that flash banner can become part
// of the cached page. This is undesirable, as re-visiting the page that was
// cached will result in the flash banner being shown briefly while the page
// is loaded in the background.
//
// We can fix this problem by hooking into the `turbolink:before-cache` event.
// This event fires just before a page is cached, allowing us to remove the
// flash banner(s) from the content before caching.
//
// This same approach can be used to collapse or expand menues to thier original
// state before caching.
//
// See: https://github.com/turbolinks/turbolinks#understanding-caching

$(document).on('turbolinks:before-cache', function () {
  $('.modoui_banner_container').remove();
});
