export default {
  channel: 'ExampleChannel',
  containerSelector: '.modoui_image_grid',
  containerStyle: {
    resizable: true,
    scroll: true,
    height: '400px',
    width: '100%'
  },
  allowedImageTypes: [
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/apng',
    'image/png'
  ],
  imageDimensions: {
    width: {
      min: 100,
      max: 1000
    },
    height: {
      min: 100,
      max: 1000
    }
  },
  maxFileSize: 1024 * 500,
  maxFileSizeString: '500 KB',
  maxImageCount: 100,
  features: {
    // Image Grid specific
    counter: { use: true, edit: false },
    emptyGridView: { use: true, edit: true },
    dragAndDrop: { use: true, edit: true },
    uploadButton: { use: true, edit: true },
    sort: { use: true, edit: true },
    select: { use: true, edit: true },
    delete: { use: false, edit: true },
    statusLog: { use: true, edit: true },
    // Image specific
    imageSelect: { use: true, edit: true }
  },
  uploadURL: 'https://example.com'
};
