$(document).on('turbolinks:load', function () {
  const presign = $('meta[name=presign]').attr('content');
  const bucket = $('meta[name=bucket]').attr('content');

  $.fn.initUppyFileControls({ presign, bucket });
  $.fn.initUppySplashFileControls({ presign, bucket });
});

$(document).on('turbolinks:before-cache', function () {
  $('.uppy').remove();
});
