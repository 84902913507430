import AbstractImage from './abstract_image';

export default class LoadingImage extends AbstractImage {
  constructor (props) {
    super(props);
    this.container = document.createElement('div');
    $(this.container).addClass('image_grid_element loading');
    this.loadingSplitPercentage = 0.8;
  }

  mount () {
    this.progressBar = document.createElement('div');
    $(this.progressBar).addClass('image_progress_bar modoui_in_progress');
    this.setProgressBar(0);
    this.container.appendChild(this.progressBar);
    this.parent.appendChild(this.container);
  }

  loadImage (image) {
    this.image = new Image();
    this.image.src = window.URL.createObjectURL(image);
    this.container.appendChild(this.image);
  }

  triggerAutoComplete (value) {
    if (value > 1.025) return;
    this.setProgressBar(value);
    // this will complete the progress bar to 1.
    setTimeout(() => this.triggerAutoComplete(value + 0.025), 125);
  }

  setProgressBar (value) {
    // value is between 0 and 1
    this.progressBar.style.cssText = `transform:scaleX(${Math.min(value, 1)})`;
  }
}
