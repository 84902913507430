$(document).on('turbolinks:load', function () {
  // submit via modal button
  const removeInput = $(':first [data-remove-config]');
  if (removeInput) {
    $(removeInput).closest('form').on('submit', function (e) {
      const form = $(this);
      form.find('[data-remove-config]').each(function () {
        const config = $(this).data('remove-config');
        const input = $('input[data-config=\'' + config + '\']');
        if (input.data('should-remove-config')) {
          input.attr('value', '!REMOVE');
        }
      });
    });
  }

  $('[data-remove-config]').on('click', function (e) {
    const config = $(this).data('remove-config');
    const input = $('input[data-config=\'' + config + '\']');
    const shouldRemove = input.data('should-remove-config');
    input.data('should-remove-config', !shouldRemove);
    input.closest('.modoui_form_row').toggleClass('modoui_form_disabled');
  });
});
