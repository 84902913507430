$(document).on('turbolinks:load', function () {
  // Removes modoui_callout* classes after number of seconds in 'data-remove-callout-delay' attribute
  $('[class*="modoui_callout"][data-remove-callout-delay]').each(function (i) {
    const item = $(this);
    window.setTimeout(function () {
      item.removeClass(function (index, className) {
        return (className.match(/(modoui_callout.*?\s)/g) || []).join(' ');
      });
    }, (item.data('remove-callout-delay') * 1000));
  });
});
