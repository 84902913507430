import AbstractImage from './abstract_image';
import ImageSelectFeature from '../features/image_select';

export default class ReceivedImage extends AbstractImage {
  constructor (props) {
    super(props);
    this.data = props.data;
    this.features = {
      select: ImageSelectFeature
    };
    this.loadingImage = props.loadingImage;
    this.canEdit = this.grid.canEdit;
    this.container = document.createElement('div');
    this.container.classList.add('image_grid_element');
    this.container.classList.add('image_grid_received');
    this.mountContainer();
    this.mountImage();
  }

  hide () {
    this.container.classList.add('image_grid_element_hidden');
  }

  show () {
    this.container.classList.remove('image_grid_element_hidden');
  }

  setPosition (newPosition) {
    this.data.position = newPosition;
    this.container.setAttribute('data-sort-position', newPosition);

    if ($('.image_grid_received').index(this.container) + 1 !== newPosition) {
      this.insertTo(newPosition);
    }
  }

  insertTo (position) {
    const images = $(this.parent).children('.image_grid_received');
    if (position === 1) {
      $(this.parent).prepend(this.container);
    } else if (position > images.length) {
      $(this.parent).append(this.container);
    } else {
      const elementInPlace = images.eq(position - 1);
      $(elementInPlace).before(this.container);
    }
  }

  mountContainer () {
    this.setPosition(this.data.position);
  }

  update (newData) {
    this.data = newData;
    this.setPosition(newData.position);
  }

  mountImage () {
    this.image = document.createElement('img');
    this.container.setAttribute('data-image-id', this.data.id);
    this.container.setAttribute('data-sort-position', this.data.position);
    this.image.src = this.data.image_url;
    this.hide();

    this.image.onload = () => {
      if (this.loadingImage) this.loadingImage.unmount();
      this.show();
      this.container.appendChild(this.image);
      this.initFeatures();
    };
  }
}
