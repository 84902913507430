import Feature from './feature';

export default class EmptyGridViewFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'emptyGridViewFeature';
    this.initEmptyView();
    this.instance.emptyViewMounted = false;
  }

  noVisibleImages () {
    return this.instance.visibleImages().length === 0;
  }

  handleGridUpdate ({ detail }) {
    const { grid } = detail;
    if (Object.keys(grid).length === 0) {
      this.mount();
    } else {
      this.unmount();
    }
  }

  handleImageDeleteStart () {
    if (this.noVisibleImages()) {
      this.mount();
    }
  }

  handleImageDeleteFail () {
    if (!this.noVisibleImages()) {
      this.unmount();
    }
  }

  handleImageUploadResolve () {
    if (this.noVisibleImages()) {
      this.mount();
    }
  }

  initEmptyView () {
    this.overlay = this.createElement('div', ['image_grid_overlay', 'image_grid_empty_view']);
    const uploadText = this.createUploadText();
    const uploadButton = this.createUploadButton();
    this.overlay.append(uploadText, uploadButton);

    const eventListeners = {
      gridUpdate: this.handleGridUpdate.bind(this),
      imageDeleteStart: this.handleImageDeleteStart.bind(this),
      imageDeleteFail: this.handleImageDeleteFail.bind(this),
      imageUploadResolve: this.handleImageUploadResolve.bind(this)
    };

    Object.keys(eventListeners)
      .map(event => this.instance.addEventListener(event, eventListeners[event]));
  }

  createUploadText () {
    const uploadText = this.createElement('div');
    uploadText.innerHTML = I18n.t('image_grid.empty_view.upload.text');
    return uploadText;
  }

  createUploadButton () {
    const uploadButtonWrapper = this.createElement('label');
    this.uploadButton = this.createElement('input');
    $(this.uploadButton).attr({
      type: 'file',
      multiple: 'true',
      accept: this.instance.options.allowedImageTypes.join(',')
    });
    this.uploadButton.addEventListener('change', () => {
      this.instance.processFiles(this.uploadButton.files);
      this.unmount();
    });
    this.uploadButtonText = this.createElement('span', ['empty_view_browse_button']);
    this.uploadButtonText.innerHTML = I18n.t('image_grid.empty_view.upload.browse');
    uploadButtonWrapper.append(this.uploadButton, this.uploadButtonText);

    return uploadButtonWrapper;
  }

  mount () {
    if (this.instance.emptyViewMounted) return;
    this.instance.emptyViewMounted = true;
    this.instance.innerWrapper.appendChild(this.overlay);
  }

  unmount () {
    if (!this.instance.emptyViewMounted) return;
    this.instance.emptyViewMounted = false;
    this.instance.innerWrapper.removeChild(this.overlay);
  }
}
