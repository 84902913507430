import Feature from './feature';

export default class DragAndDropFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'dragAndDropFeature';
    this.counter = 0;
    this.initText();

    window.addEventListener('dragenter', e => {
      e.preventDefault();
      e.stopPropagation();
      this.counter += 1;
      this.mount();
    }, true);

    window.addEventListener('dragleave', e => {
      e.stopPropagation();
      this.counter -= 1;
      if (this.counter > 0) return;
      this.unmount();
    }, true);

    this.overlay.addEventListener('drop', e => {
      e.preventDefault();
      this.instance.processFiles(e.dataTransfer.files);
      this.unmount();
      if (this.instance.emptyViewMounted) {
        this.instance.emptyGridViewFeature.unmount();
      }
    });

    window.addEventListener('dragover', function (e) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'copy';
      e.dataTransfer.dropEffect = 'copy';
    });
  }

  initText () {
    this.overlay = this.createElement('div', ['image_grid_overlay', 'image_grid_highlight']);
    this.overlay.innerHTML = I18n.t('image_grid.drag_and_drop.overlay_text');
    this.mounted = false;
  }

  mount () {
    if (this.mounted) return;
    $('.image_grid_overlay').hide();
    this.mounted = true;
    this.instance.innerWrapper.appendChild(this.overlay);
  }

  unmount () {
    if (!this.mounted) return;
    this.mounted = false;
    this.counter = 0;
    this.instance.innerWrapper.removeChild(this.overlay);
    $('.image_grid_overlay').show();
  }
}
