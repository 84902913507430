import modouiDevicePreview from '~/lib/modoui_device_preview';

$(document).on('turbolinks:load', function () {
  if ($('.modoui_device_preview').length) {
    /* JS for the Device Preview launchscreen
         * instantiate the preview device and its content
         */
    modouiDevicePreview.initDevice(this);

    /* Handle the device preview device type change events
        */
    $('.modoui_device_preview_selector a').on('click', function () {
      modouiDevicePreview.switchDevice(this);
    });
    /* Handle the rotate device preview change events
        */
    $('.modoui_device_preview_rotate a').on('click', function () {
      modouiDevicePreview.rotateDeviceToggle($('#modoui_device_preview_item'));
    });
  }
});
