import ImageGrid from '~/lib/image_grid/image_grid';

// load the image grid into the page
$(document).on('turbolinks:load', function () {
  const wrapperSelector = '#sticker_pack_images';

  if ($(wrapperSelector).length === 0) {
    return;
  }

  const wrapper = $(wrapperSelector)[0];

  const options = {
    channel: 'StickerPackChannel',
    containerStyle: {
      scroll: true,
      height: '500px',
      width: '100%'
    },
    features: {
      sort: { use: true },
      delete: { use: true }
    },
    uploadURL: window.location.pathname + '/stickers'
  };

  window.modouiImageGrid = new ImageGrid({ wrapper, options });
});
