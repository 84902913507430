export default class PresignProvider {
  constructor ({ url, threshold }) {
    this.presignURL = url;
    this.threshold = threshold || 50;
    this.presigns = [];
    this.populate();
  }

  get (count) {
    const presignsToGive = this.presigns.splice(0, Math.min(count, this.presigns.length));
    const presignsLeftToGive = count - presignsToGive.length;

    if (presignsLeftToGive === 0) {
      // used some of the presigns so populate
      this.populate();
      return Promise.resolve(presignsToGive);
    } else {
      return new Promise(resolve => {
        // used all up so wait for populate and request again
        this.populate()
          // r e c u r s i o n
          .then(() => this.get(presignsLeftToGive))
          // got all presigns, now combine it with the current presigns and resolve
          .then(restOfThePresigns => resolve(presignsToGive.concat(restOfThePresigns)));
      });
    }
  }

  // makes the number of presigns available = threshold
  populate () {
    // make sure there are never more than threshold presigns available
    const count = Math.min(this.threshold - this.presigns.length, this.threshold);
    return new Promise(resolve => {
      if (count <= 0) {
        resolve();
      }

      Rails.ajax({
        type: 'POST',
        url: this.presignURL,
        data: { count },
        success: (newPresigns) => {
          this.presigns = this.presigns.concat(newPresigns);
          resolve();
        }
      });
    });
  }
}
