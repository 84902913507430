import Feature from './feature';

export default class SelectFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'selectFeature';
    this.selected = false;
    this.previousSelected = null;
    this.drag = false;
    this.draggedElement = null;

    this.createControlsForSelectMode();
    this.mountControls();

    $(this.instance.container).on('sortstop', (e, { item }) => {
      this.draggedElement = item[0];
      this.drag = true;
    });

    this.instance.addEventListener('selectUpdate', () => {
      this.selectControls.counter.innerHTML = this.counterText();
    });

    this.dragSelectedImages = [];
    this.initDragSelect();
  }

  initDragSelect () {
    const boundHandleMouseMove = this.handleMouseMove.bind(this);

    this.instance.addEventListener('mousedown', e => {
      // return if clicking on an image
      if (e.target !== e.currentTarget) return;
      document.addEventListener('mousemove', boundHandleMouseMove);
      this.mountDragBox();
    });

    document.addEventListener('mouseup', e => {
      const isNotAnImage = $(e.target).parents('.image_grid_received').length === 0 && !$(e.target).hasClass('image_grid_received');
      const isNotAButton = !$(e.target).hasClass('btn');
      const isNotAToolbarElement = $(e.target).parents('.image_grid_toolbar').length === 0;
      const isNotAToolbarButton = isNotAButton && isNotAToolbarElement;
      const isNotAModalElement = $(e.target).parents('.modal').length === 0;

      // case when the user has clicked outside the images & not dragged a selection
      // with images selected, or clicked a toolbar button.
      if (isNotAnImage && isNotAToolbarButton && isNotAModalElement && this.dragSelectedImages.length === 0) {
        this.deselectAndUpdate();
      }

      document.removeEventListener('mousemove', boundHandleMouseMove);
      this.unmountDragBox();
    });

    this.initDragBox();
  }

  deselectAndUpdate () {
    this.deselectAll();
    this.dispatchUpdateEvent();
  }

  initDragBox () {
    this.dragBox = document.createElement('div');
    this.dragBox.classList.add('image_grid_dragbox');
    this.dragSelectedImages = [];
  }

  mountDragBox () {
    this.dragBoxMounted = true;
    $('.modoui_container_main').addClass('image_grid_active');
    this.instance.container.classList.add('drag_active');
    this.instance.container.appendChild(this.dragBox);
    this.dragSelectedImages = [];
  }

  unmountDragBox () {
    this.dragBox.style.cssText = '';
    this.dragStart = null;
    this.dragWidth = 0;
    this.dragHeight = 0;
    if (this.dragBoxMounted) {
      this.instance.container.removeChild(this.dragBox);
      $('.modoui_container_main').removeClass('image_grid_active');
      this.instance.container.classList.remove('drag_active');
      this.dragBoxMounted = false;
    }
    this.dragSelectedImages = [];
  }

  addToDragSelection (image) {
    if (this.dragSelectedImages.indexOf(image) === -1) {
      this.dragSelectedImages.push(image);
    }
  }

  removeFromDragSelection (image) {
    const index = this.dragSelectedImages.indexOf(image);
    if (index !== -1) {
      this.dragSelectedImages.splice(index, 1);
    }
  }

  updateStyle () {
    const { x, y } = this.dragStart;
    const left = this.negativeX ? x - this.dragWidth : x;
    const top = this.negativeY ? y - this.dragHeight : y;
    this.dragBox.style.cssText = `
    width:${this.dragWidth}px;
    height:${this.dragHeight}px;
    left:${left}px;
    top:${top}px`;
  }

  handleMouseMove ({ pageX, pageY }) {
    const container = this.instance.container;
    const offset = $(container).offset();
    const x = pageX - offset.left;
    const y = pageY - offset.top + container.scrollTop;

    this.dragStart = this.dragStart || { x, y };

    const width = x - this.dragStart.x;
    const height = y - this.dragStart.y;

    this.negativeX = width < 0;
    this.negativeY = height < 0;

    const containerBottom = offset.top + $(container).outerHeight();
    const containerTop = offset.top;

    // scroll when the dragbox is at the edge
    if (containerBottom - pageY < 1) {
      this.scrollContainer(3, 4);
    }
    if (pageY - containerTop < 1) {
      this.scrollContainer(-3, 4);
    }

    // decide which images to add to the selection
    Object.keys(this.instance.gridImages).map(id => {
      const image = this.instance.gridImages[id];
      const isSelected = image.imageSelectFeature.instance.selected;
      const dragBounds = this.getBounds(this.dragBox);
      const isWithinBounds = this.isWithinBounds(image.container, dragBounds);

      if (isWithinBounds && !isSelected) {
        this.addToDragSelection(image);
        image.imageSelectFeature.keepSelecting();
        this.previousSelected = image.container;
      } else if (!isWithinBounds && isSelected) {
        this.removeFromDragSelection(image);
        image.imageSelectFeature.deselectAndUpdate();
      }

      this.dispatchUpdateEvent(image.container);
    });
    this.dragWidth = Math.abs(width);
    // small hack to stop the container from growing with the dragbox
    if ($(container).prop('scrollHeight') - 5 > y) {
      this.dragHeight = Math.abs(height);
    }
    this.updateStyle();
  }

  scrollContainer (amount, counter) {
    // fakes a smooth scroll
    if (counter === 0) return;
    this.instance.container.scrollTop += amount;
    setTimeout(() => this.scrollContainer(amount, counter - 1), 20);
  }

  isWithinBounds (image, dragBounds) {
    const imageBounds = this.getBounds(image);

    // horizontal check
    if (imageBounds.left > dragBounds.right || dragBounds.left > imageBounds.right) return false;

    // vertical check
    if (imageBounds.top > dragBounds.bottom || imageBounds.bottom < dragBounds.top) return false;
    return true;
  }

  getBounds (element) {
    const offset = $(element).offset();
    return {
      left: offset.left,
      top: offset.top,
      right: offset.left + $(element).outerWidth(),
      bottom: offset.top + $(element).outerHeight()
    };
  }

  selectedImages () {
    return Object.keys(this.instance.gridImages).map(k => this.instance.gridImages[k]).filter(image => image.selected);
  }

  updateState () {
    const selectedCount = this.selectedImages().length;
    const nothingSelected = selectedCount === 0;
    this.selected = !nothingSelected;
    this.toggleControls(nothingSelected);
  }

  createControlsForSelectMode () {
    const clearButton = this.toolbar.createToolbarButton(I18n.t('image_grid.toolbar.deselect'));
    $(clearButton).addClass('modoui_quiet modoui_disabled');
    clearButton.addEventListener('click', () => {
      this.deselectAll();
      this.dispatchUpdateEvent();
    });

    const counter = document.createElement('div');
    counter.classList.add('select_counter');
    counter.innerHTML = this.counterText();

    this.selectControls = { counter, clearButton };
  }

  counterText () {
    return I18n.t('image_grid.toolbar.selected', { count: this.selectedImages().length });
  }

  mountControls () {
    Object.keys(this.selectControls).map(k => this.selectControls[k]).forEach(this.toolbar.addToToolbar.bind(this.toolbar));
  }

  toggleControls (value) {
    $(this.selectControls.clearButton).toggleClass('modoui_disabled', value);
  }

  deselectAll (filter = []) {
    const gridImages = this.instance.gridImages;
    Object.keys(gridImages).map(k => gridImages[k]).forEach(image => {
      // filter includes the id, return
      if (filter.indexOf(image.data.id) !== -1) return;
      image.imageSelectFeature.deselect();
    });
    this.updateState();
  }

  dispatchUpdateEvent (element, event) {
    const selectUpdate = new CustomEvent('selectUpdate', { detail: { element, event, feature: this } });
    this.instance.container.dispatchEvent(selectUpdate);
  }
}
