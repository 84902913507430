import Feature from './feature';

export default class DeleteFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'deleteFeature';
    this.deleteButton = this.createButton();
    this.toolbar.addToToolbar(this.deleteButton);
    this.addEventListener();
  }

  createButton () {
    const deleteButton = this.toolbar.createToolbarButton(I18n.t('image_grid.toolbar.delete'));
    $(deleteButton).addClass('modoui_destructive modoui_disabled');
    deleteButton.setAttribute('data-toggle', 'modal');
    deleteButton.setAttribute('data-target', '#stickerDeleteModal');
    deleteButton.addEventListener('click', () => {
      const count = this.instance.selectFeature.selectedImages().length;
      $('#stickerDeleteModal .modal-body p').html(I18n.t('image_grid.delete.modal_prompt', { count }));
    });

    document.getElementById('sticker_delete_button').addEventListener('click', this.deleteAction.bind(this));
    return deleteButton;
  }

  dispatchContainerEvent (eventName) {
    this.instance.dispatchContainerEvent(new CustomEvent(eventName, { bubbles: false }));
  }

  deleteAction () {
    const selectedImages = this.instance.selectFeature.selectedImages();
    const url = this.instance.container.getAttribute('data-delete-url');
    const selectedImageIds = selectedImages.map(image => ['ids[]', image.data.id]);

    selectedImages.forEach(image => image.hide());
    this.dispatchContainerEvent('imageDeleteStart');

    this.instance.dispatchLogClear();

    const successMessage = I18n.t('image_grid.delete.successful', { count: selectedImages.length });
    const failedMessage = I18n.t('image_grid.delete.failed', { count: selectedImages.length });
    const data = new URLSearchParams(selectedImageIds).toString();

    Rails.ajax({
      type: 'delete',
      url,
      data,
      success: () => this.instance.dispatchLogEvent({ type: 'success', summary: successMessage }),
      error: () => {
        this.dispatchContainerEvent('imageDeleteFail');
        this.instance.dispatchLogEvent({ type: 'alarm', summary: failedMessage });
        selectedImages.forEach(image => image.show());
      }
    });

    this.instance.selectFeature.deselectAll();
    this.instance.selectFeature.dispatchUpdateEvent();
  }

  addEventListener () {
    this.instance.addEventListener('selectUpdate', ({ detail }) => {
      $(this.deleteButton).toggleClass('modoui_disabled', !detail.feature.selected);
    });
  }
}
