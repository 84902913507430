import UIElementWithFeatures from '../ui_element_with_features';

export default class AbstractImage extends UIElementWithFeatures {
  constructor (props) {
    super(props);
    const { grid, parent, options } = props;
    this.grid = grid;
    this.parent = parent;
    this.options = options;
  }

  unmount () {
    if (this.parent.contains(this.container)) {
      this.parent.removeChild(this.container);
    }
  }
}
