export default class Feature {
  constructor (props) {
    this.instance = props.instance;
    this.toolbar = props.instance.toolbar;
  }

  createElement (type, classNames = []) {
    const element = document.createElement(type);
    if (classNames.length > 0) {
      $(element).addClass(classNames.join(' '));
    }
    return element;
  }
}
