// set the options for the modoui tooltip
$(document).on('turbolinks:load', function () {
  $('.modoui_inline_help').tooltip({
    html: false,
    animation: true,
    trigger: 'hover focus'
  });
});

// init tooltips on this screen don't allow pass thru html in case of hijacking for now
$(document).on('turbolinks:load', function () {
  // instantiate any/all tooltips on page
  $('[data-toggle="tooltip"]').tooltip();
});
