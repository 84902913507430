import consumer from '../consumer';

export default class ImageGridConnection {
  constructor (props) {
    this.instance = props.instance;
    this.channelID = this.instance.container.getAttribute('data-id');
    this.firstBroadcastReceived = false;

    this.channelData = {
      channel: this.instance.options.channel,
      id: this.channelID
    };

    this.callbacks = {
      disconnected: this.reconnect.bind(this),
      rejected: () => { this.overlay.innerHTML = I18n.t('image_grid.connection.rejected'); },
      received: this.handleData.bind(this)
    };

    this.createOverlay();
    this.mount();
    this.overlay.innerHTML = I18n.t('image_grid.connection.connecting');
    this.connect();
  }

  connect () {
    // making sure there isn't a previous connection
    // if there is, remove the connection.
    if (window.modouiImageGrid) {
      consumer.subscriptions.remove(window.modouiImageGrid.connection.subscription);
      window.setTimeout(this.createConnection.bind(this), 1000);
    } else {
      this.createConnection();
    }
  }

  dispatchEvent (eventName) {
    this.instance.dispatchContainerEvent(new CustomEvent(eventName, { bubbles: false }));
  }

  createConnection () {
    this.subscription = consumer.subscriptions.create(this.channelData, this.callbacks);
  }

  reconnect () {
    this.dispatchEvent('imageGridCableDisconnected');
    this.overlay.innerHTML = I18n.t('image_grid.connection.reconnecting');
    this.mount();
  }

  createOverlay () {
    this.overlay = document.createElement('div');
    $(this.overlay).addClass('image_grid_overlay image_grid_connection_overlay');
  }

  mount () {
    if (this.instance.innerWrapper.contains(this.overlay)) return;
    this.instance.innerWrapper.appendChild(this.overlay);
  }

  unmount () {
    if (!this.instance.innerWrapper.contains(this.overlay)) return;
    this.instance.innerWrapper.removeChild(this.overlay);
  }

  handleData (data) {
    // use this function to create different types of messages
    if (!this.firstBroadcastReceived) {
      this.dispatchEvent('imageGridCableConnected');
      this.unmount();
      this.firstBroadcastReceived = true;
    }
    this.instance.updateGrid(data);
  }
}
