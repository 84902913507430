export default class ImageGridToolbar {
  constructor ({ instance }) {
    this.instance = instance;
    this.mount();
    this.handleTurbolinksCaching();
  }

  mount () {
    this.container = document.createElement('div');
    this.container.classList.add('image_grid_toolbar');
    this.instance.wrapper.insertBefore(this.container, this.instance.innerWrapper);
  }

  createToolbarButton (text) {
    const button = document.createElement('div');
    $(button).addClass('btn modoui_btn');
    button.innerHTML = text;
    return button;
  }

  addToToolbar (element) {
    this.container.appendChild(element);
  }

  removeFromToolbar (element) {
    this.container.removeChild(element);
  }

  handleTurbolinksCaching () {
    $(document).on('turbolinks:before-cache', () => $(this.container).remove());
  }
}
