(function () {
  $(document).on('turbolinks:load', function () {
    document.getElementsByName('build[distribution_option]').forEach(item => {
      item.addEventListener('change', event => {
        const unsignedCheckboxSection = document.getElementById('unsignedCheckboxSection');

        if (!unsignedCheckboxSection) {
          return;
        }

        if (event.target.value.endsWith('adhoc')) {
          unsignedCheckboxSection.style.display = 'block';
        } else {
          unsignedCheckboxSection.style.display = 'none';
          const unsignedCheckbox = document.getElementById('build_unsigned');
          unsignedCheckbox.checked = false;
        }
      });
    });
  });
}).call(this);
