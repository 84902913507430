import consumer from '~/lib/consumer';

$(document).on('turbolinks:load', function () {
  const syncButton = $('#sync_certs');

  if (syncButton.length) {
    const nativeAppId = syncButton.data('action-cable').native_app_id;

    const channelData = {
      channel: 'Ios::Certificates::CodeSign::PortalSyncChannel',
      native_app_id: nativeAppId
    };

    const callbacks = {

      initialized: function () {
        this.show_spinner = this.show_spinner.bind(this);
        this.sync = this.sync.bind(this);
      },

      received: function (data) {
        if (data.new_cells) {
          $.each(data.new_cells, function (_, cell) {
            $('.action_cable[data-cert-id="' + cell.id + '"]').html(cell.content);
          });

          toggleSyncButton(false);
        }

        if (data.spinner) {
          $('.portal-status').each(function () {
            $(this).html(data.spinner);
          });
        }

        if (data.sync_button_status) {
          toggleSyncButton(data.sync_button_status === 'disabled');
        }
      },

      show_spinner: function () {
        this.perform('show_spinner', { native_app_id: nativeAppId });
      },

      sync: function () {
        this.perform('portal_sync_status', { native_app_id: nativeAppId });
      }

    };

    const portalSync = consumer.subscriptions.create(
      channelData, callbacks
    );

    $(document).on('click', '#sync_certs', function (e) {
      e.preventDefault();
      e.stopPropagation();

      syncButton.blur();
      if (syncButton.hasClass('modoui_disabled')) return;

      portalSync.show_spinner();
      portalSync.sync();

      toggleSyncButton(true);
    });

    const toggleSyncButton = function (state) {
      syncButton.toggleClass('modoui_disabled', state);
    };
  }
});
