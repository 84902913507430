import Feature from './feature';

export default class SortFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'sortFeature';

    this.selectedItems = null;
    this.selectedItemsExceptTarget = null;
    this.startIndex = null;

    $(this.instance.container).sortable({
      items: '.image_grid_received',
      revert: 0,
      delay: 150,
      helper: (e, item) => {
        if (!item.hasClass('selected')) {
          const id = item.data('image-id');
          this.instance.gridImages[id].imageSelectFeature.selectJustThis();
          this.instance.selectFeature.dispatchUpdateEvent();
        }
        this.selectedItems = item.parent().children('.selected');
        this.selectedItemsExceptTarget = this.selectedItems.not(item);

        const selectedItemsArray = this.selectedItems.toArray();
        const initialIndex = selectedItemsArray.indexOf(item[0]);

        this.startIndex = $(this.selectedItems[0]).index();
        this.selectedItemsBeforeItem = selectedItemsArray.slice(0, initialIndex);
        this.selectedItemsAfterItem = selectedItemsArray.slice(initialIndex + 1);

        const cloneElements = this.selectedItems.clone();
        const helper = $('<div class="sortable-helper"/>');
        return helper.append(cloneElements);
      },
      start: (e, ui) => {
        this.selectedItemsExceptTarget.addClass('currently_sorting');
        ui.helper.append(`<div class='sort_count'>${this.selectedItems.length}</div>`);
        ui.placeholder.removeClass('selected');
      },
      beforeStop: (e, ui) => {
        // endIndex: where the placeholder ends up, used to compare with the index of the first element
        // in the selection
        // dropIndex: where the batch gets dropped, relative to the whole grid, the actual value
        // sent to the server.
        this.endIndex = $(this.instance.container).children().not('.selected').index(ui.placeholder);
        this.dropIndex = ui.placeholder.index();
      },
      stop: (e, info) => {
        this.selectedItemsExceptTarget.removeClass('currently_sorting');

        $(this.selectedItemsBeforeItem).insertBefore(info.item);
        $(this.selectedItemsAfterItem).insertAfter(info.item);

        info.item.css('display', '');

        const samePositionAsBeginning = this.startIndex === this.endIndex;
        const itemsAreConsecutive = this.areItemsConsecutive(this.selectedItems.toArray());

        // if the user has not moved anything to a different spot, don't update the server.
        if (samePositionAsBeginning && itemsAreConsecutive) return;

        this.sendSortUpdateToServer(e, info);
      }
    });
  }

  areItemsConsecutive (items) {
    return items.every((item, index) => {
      const position = parseInt(item.getAttribute('data-sort-position'));
      const nextPosition = () => parseInt(items[index + 1].getAttribute('data-sort-position'));
      return index === items.length - 1 || position + 1 === nextPosition();
    });
  }

  sendSortUpdateToServer (e, info) {
    const sortedStickerIds = this.selectedItems.toArray().map(item => item.getAttribute('data-image-id'));
    const url = this.instance.container.getAttribute('data-order-url');
    const params = new URLSearchParams({ new_index: this.dropIndex });

    sortedStickerIds.forEach(stickerId => {
      params.append('sticker_ids[]', stickerId);
    });

    this.updateElementPositions();
    this.instance.dispatchLogClear();

    const successMessage = I18n.t('image_grid.sort.successful', { count: sortedStickerIds.length });
    const failureMessage = I18n.t('image_grid.sort.failed', { count: sortedStickerIds.length });

    Rails.ajax({
      type: 'patch',
      url,
      data: params.toString(),
      success: () => this.instance.dispatchLogEvent({ type: 'success', summary: successMessage }),
      error: () => {
        this.instance.dispatchLogEvent({ type: 'alarm', summary: failureMessage });
        $(this.instance.container).sortable('cancel');
      }
    });
  }

  updateElementPositions () {
    const orderArray = $(this.instance.container)
      .sortable('toArray', { attribute: 'data-sort-position' })
      .map(position => parseInt(position));
    Object.keys(this.instance.gridImages).forEach(imageID => {
      const image = this.instance.gridImages[imageID];
      const newPosition = orderArray.indexOf(image.data.position) + 1;
      if (newPosition !== image.data.position) {
        image.setPosition(newPosition);
      }
    });
  }
}
