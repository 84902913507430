import DataTable from 'datatables.net-dt';

var telchar = telchar || {};
telchar.DataTable = {
  defaults: {
    dom: '<"nav_table framed"t>ipr',
    paging: true,
    ordering: true,
    info: true,
    searching: false,
    lengthChange: false,
    pageLength: 20,
    pagingType: 'full_numbers',
    serverSide: true,
    processing: false,
    autoWidth: true,
    destroy: true,
    language: {
      aria: {
        sortAscending: '',
        sortDescending: ''
      }
    }
  },

  init (tableId, options) {
    if ($(tableId).length === 0) { return; }

    return new DataTable(tableId, $.extend(
      true,
      {},
      telchar.DataTable.defaults,
      { ajax: $(tableId).data('source') },
      options
    ));
  }
};

// Destroy data table before turbolinks cache to prevent duplicate initialization
$(document).on('turbolinks:before-cache', () => $('.dataTable').DataTable().destroy());

$(document).on('turbolinks:load', function () {
  telchar.DataTable.init('#admin_all_teams', {
    dom: 'f<"nav_table framed"t>ipr',
    searching: true,
    order: [[0, 'asc']],
    columns: [
      { name: 'name', data: 'name' },
      { name: 'description', data: 'description' },
      { name: 'users_count', data: 'users_count', sortable: false },
      { name: 'actions', data: 'actions', sortable: false }
    ]
  });

  telchar.DataTable.init('#admin_team_privileges', {
    paging: false,
    ordering: false,
    info: false,
    order: [[1, 'desc']],
    columns: [
      { name: 'name', data: 'name' },
      { name: 'description', data: 'description' },
      { name: 'actions', data: 'actions', sortable: false }
    ]
  });

  telchar.DataTable.init('#admin_team_memberships', {
    paging: false,
    ordering: false,
    info: false,
    order: [[1, 'desc']],
    columns: [
      { name: 'name', data: 'name' },
      { name: 'email', data: 'email' }
    ]
  });

  telchar.DataTable.init('#all_builds_list', {
    dom: 'f<"nav_table framed"t>ipr',
    searching: true,
    order: [[4, 'desc']],
    columns: [
      { name: 'status', data: 'status', sortable: false, className: 'text-center' },
      { name: 'version_code', data: 'details' },
      { name: 'app_version_id', data: 'version' },
      { name: 'short_code', data: 'short_code' },
      { name: 'created_at', data: 'build_date' },
      { name: 'actions', data: 'actions', sortable: false }
    ]
  });

  telchar.DataTable.init('#admin_builds_list', {
    dom: 'f<"nav_table framed"t>ipr',
    searching: true,
    order: [[5, 'desc']],
    columns: [
      { name: 'status', data: 'status', sortable: false, className: 'text-center' },
      { name: 'platform', data: 'platform', sortable: false, className: 'text-center' },
      { name: 'version_code', data: 'details' },
      { name: 'app_version_id', data: 'version' },
      { name: 'short_code', data: 'short_code' },
      { name: 'created_at', data: 'build_date' },
      { name: 'actions', data: 'actions', sortable: false }
    ]
  });

  telchar.DataTable.init('#admin_apns_certificates', {
    dom: 'f<"nav_table framed"t>ipr',
    searching: true,
    ordering: false,
    info: false,
    order: [[4, 'desc']],
    columns: [
      { name: 'certificate', data: 'certificate' },
      { name: 'native_app', data: 'native_app' },
      { name: 'status', data: 'status', className: 'text-center' },
      { name: 'type', data: 'type' },
      { name: 'expires', data: 'expires' },
      { name: 'credentials', data: 'credentials', className: 'text-center' },
      { name: 'actions', data: 'actions', sortable: false }
    ]
  });

  return new DataTable('#admin_native_apps', { paging: true, searching: true });
});
