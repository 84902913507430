import Rails from '@rails/ujs';
import '~/lib/set_globals';
import 'jquery-ui';
import 'jquery-ui/ui/data';
import 'jquery-ui/ui/scroll-parent';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/sortable';
import 'timeago/jquery.timeago.js';
import { bootstrap } from 'bootstrap';
import 'spectrum-colorpicker';

import '@modolabs/modoui-js/js/admin_banners';
import '@modolabs/modoui-js/js/aria_announcer';
import '@modolabs/modoui-js/js/image_upload';
import '@modolabs/modoui-js/js/leftnav';
import '@modolabs/modoui-js/js/tooltip_accessibility';
import '@modolabs/modoui-js/js/utilities';

import '~/lib/channels/build_row_updates_received.js';
import '~/lib/channels/builds_status_received.js';
import '~/lib/channels/portal_sync_status_updates_received.js';
import '~/lib/build.js';
import '~/lib/datatables.js';
import '~/lib/file_upload.js';
import '~/lib/forms.js';
import '~/lib/header/user-menu.js';
import '~/lib/init_select2.js';
import '~/lib/navbar.js';
import '~/lib/remove_callout_delay.js';
import '~/lib/remove_value.js';
import '~/lib/set_tz.js';
import '~/lib/sticker_pack_image_grid.js';
import '~/lib/theme_form.js';
import '~/lib/theme_preview.js';
import '~/lib/timeago.js';
import '~/lib/tooltip.js';
import '~/lib/translations.js';
import '~/lib/turbolinks-before-cache.js';
import '~/lib/ui/admin_banners.js';
import '~/lib/uploads/splash_upload_area.js';
import '~/lib/uploads/upload_area.js';

import Turbolinks from 'turbolinks';
Turbolinks.start();
