import Feature from './feature';

export default class UploadButtonFeature extends Feature {
  constructor (props) {
    super(props);
    this.initUploadButton();
    this.toolbar.addToToolbar(this.uploadButton);
    this.instance.addEventListener('imageGridCableConnected', () => {
      this.uploadButton.classList.remove('modoui_disabled');
    });
    this.instance.addEventListener('imageGridCableDisconnected', () => {
      this.uploadButton.classList.add('modoui_disabled');
    });
  }

  initUploadButton () {
    const uploadButtonWrapper = document.createElement('label');
    const uploadButton = document.createElement('input');
    $(uploadButtonWrapper).addClass('btn modoui_btn modoui_constructive modoui_disabled');
    $(uploadButton).attr({
      type: 'file',
      multiple: 'true',
      accept: this.instance.options.allowedImageTypes.join(',')
    });
    uploadButton.addEventListener('change', () => {
      this.instance.processFiles(uploadButton.files);
      if (this.instance.emptyViewMounted) {
        this.instance.emptyGridViewFeature.unmount();
      }
    });
    const uploadButtonText = document.createElement('span');
    uploadButtonText.innerHTML = I18n.t('image_grid.toolbar.upload');
    uploadButtonWrapper.append(uploadButton, uploadButtonText);
    this.uploadButton = uploadButtonWrapper;
  }
}
