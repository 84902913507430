import Feature from './feature';

export default class ImageSelectFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'imageSelectFeature';
    this.gridSelectFeature = this.instance.grid.selectFeature;
    this.instance.selected = false;
    this.instance.container.addEventListener('mouseup', this.handleClick.bind(this));
  }

  handleClick (e) {
    const dragSelectedImageCount = this.gridSelectFeature.dragSelectedImages.length;

    const thisWasDragged = this.gridSelectFeature.draggedElement === this.instance;
    // If this event was fired when a drag was released
    // on top of an image, return.
    if ((this.gridSelectFeature.drag && thisWasDragged) || dragSelectedImageCount > 0) {
      this.gridSelectFeature.drag = false;
      return;
    }

    // Saving the previous selected state to compare
    // before changing it.
    const wasSelectedBefore = this.instance.selected;

    this.updateSelectedState(true);

    if (this.instance.selected) {
      this.handleSelect(e, wasSelectedBefore);
    } else {
      this.handleDeselect(e);
    }

    this.gridSelectFeature.dispatchUpdateEvent(this.instance, e);
  }

  updateSelectedState (value) {
    this.instance.grid.selected = value;
    this.instance.selected = value;
  }

  updatePreviousSelected () {
    this.gridSelectFeature.previousSelected = this.instance.container;
  }

  handleSelect (e, wasSelectedBefore) {
    const ctrlOrCommandIsPressed = e.ctrlKey || e.metaKey;
    const shiftIsPressed = e.shiftKey;

    if (ctrlOrCommandIsPressed && wasSelectedBefore) {
      // Command + Click on a selected image will deselect it
      this.deselectAndUpdate();
    } else if (ctrlOrCommandIsPressed) {
      // Command + Click on a unselected image will deselect it
      this.keepSelecting();
      this.updatePreviousSelected();
    } else if (shiftIsPressed && this.gridSelectFeature.selected) {
      // Shift + Click on an unselected image will select all the images
      // between the previous selected image to this image.
      // clear the previous shift range
      this.deselectPreviousShiftSelectRange();
      this.selectShiftSelectRange(this.calculateNewShiftRange());
    } else {
      // This also includes the case when shift click happens when there
      // isn't a previous selected image in the grid.
      this.selectJustThis();
      this.updatePreviousSelected();
    }
  }

  calculateNewShiftRange () {
    const prevItem = $(this.gridSelectFeature.previousSelected);
    const currentItem = $(this.instance.container);

    const prevIndex = prevItem ? prevItem.index() : 0;
    const curIndex = currentItem.index();

    if (prevIndex < curIndex) {
      // Select towards the beginning of the grid
      return currentItem.prevUntil(prevItem).add(prevItem).add(currentItem);
    } else if (prevIndex > curIndex) {
      // Select towards end of the grid
      return currentItem.nextUntil(prevItem).add(prevItem).add(currentItem);
    } else {
      // Select only this item
      return currentItem;
    }
  }

  selectShiftSelectRange (shiftRange) {
    shiftRange.each((index, item) => {
      if ($(item).hasClass('ui-sortable-placeholder')) return;

      const id = item.getAttribute('data-image-id');
      const image = this.instance.grid.gridImages[id];

      image.imageSelectFeature.updateSelectedState(true);
      image.imageSelectFeature.keepSelecting();
      image.container.classList.add('shift_select');
    });
  }

  deselectPreviousShiftSelectRange () {
    $('.shift_select').each((index, item) => {
      if ($(item).hasClass('ui-sortable-placeholder')) return;

      const id = item.getAttribute('data-image-id');
      const image = this.instance.grid.gridImages[id];

      image.imageSelectFeature.deselect();
      image.container.classList.remove('shift_select');
    });
  }

  handleDeselect (e) {
    if (this.gridSelectFeature.selectedImages().length > 0) {
      this.updateSelectedState(true);
      this.deselectEverythingButThis();
    } else {
      this.deselectAndUpdate();
    }
  }

  deselectEverythingButThis () {
    this.gridSelectFeature.deselectAll([this.instance.data.id]);
  }

  keepSelecting () {
    this.instance.selected = true;
    this.instance.container.classList.add('selected');
    this.gridSelectFeature.updateState();
  }

  selectJustThis () {
    this.instance.selected = true;
    this.instance.container.classList.add('selected');
    this.instance.grid.selectFeature.deselectAll([this.instance.data.id]);
  }

  deselect () {
    if (!this.instance.selected) return;
    this.instance.selected = false;
    this.instance.container.classList.remove('selected', 'shift_select');
  }

  deselectAndUpdate () {
    this.deselect();
    this.instance.grid.selectFeature.updateState();
  }
}
