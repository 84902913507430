import consumer from '../consumer';

$(document).on('turbolinks:load', function () {
  let buildId;

  if ($('.action_cable').data('actionCableChannel') === 'builds_status') {
    buildId = $('.action_cable').data('actionCableBuildId');

    consumer.subscriptions.create(
      {
        channel: 'BuildsStatusChannel',
        build_id: buildId
      },
      {
        received: function (data) {
          location.reload();
        }
      }
    );
  }
});
