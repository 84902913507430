import Feature from './feature';

export default class StatusLogFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'statusLog';
    window.statusLogger = this;
    this.init();
    this.mount();
  }

  init () {
    this.container = document.createElement('div');
    this.container.classList.add('image_grid_status_log_container');
    this.instance.addEventListener('imageGridLogEntry', ({ detail }) => {
      const summary = detail.summary || I18n.t('image_grid.status_log.default_message');
      const type = detail.type || 'success';
      const data = { type, summary, items: detail.items };
      const logEntry = this.createLogEntry(data);
      this.addToLog(logEntry);
      this.makeAriaAnnouncement(data);
    });
    this.instance.addEventListener('imageGridLogClear', this.unmount.bind(this));
  }

  mount () {
    this.instance.wrapper.appendChild(this.container);
  }

  unmount () {
    if (!this.instance.wrapper.contains(this.container)) return;
    $(this.container).children('.log_entry').remove();
  }

  makeAriaAnnouncement (type, summary, items = []) {
    [type, summary, ...items].forEach(item => $.aria_announcer.announce(item));
  }

  addToLog (logEvent) {
    this.container.appendChild(logEvent);
  }

  createLogEntry ({ type, summary, items = [] }) {
    const logEntry = document.createElement('div');
    logEntry.classList.add('log_entry');

    const summaryContainer = document.createElement('div');
    $(summaryContainer).addClass(`log_summary log_type_${type}`);
    summaryContainer.innerHTML = summary;

    logEntry.append(summaryContainer);

    if (items.length > 0) {
      const logItemsList = document.createElement('ul');
      logItemsList.classList.add('log_items');
      const logItemElements = items.map(logItem => {
        const element = document.createElement('li');
        element.innerHTML = logItem;
        return element;
      });
      logItemsList.append(...logItemElements);
      logEntry.append(logItemsList);
    }

    return logEntry;
  }
}
