import UIElementWithFeatures from './ui_element_with_features';
import ImageGridConnection from './image_grid_connection';
import defaultOptions from './options';
import PresignProvider from './presign_provider';
import ImageGridToolbar from './toolbar';

// Image Classes
import ReceivedImage from './images/received_image.js';
import LoadingImage from './images/loading_image.js';
import { UploadImage, UPLOAD_IMAGE_ERROR } from './images/upload_image.js';

// Features
import CounterFeature from './features/counter';
import DeleteFeature from './features/delete';
import DragAndDropFeature from './features/drag_and_drop';
import EmptyGridViewFeature from './features/empty_view';
import SelectFeature from './features/select';
import SortFeature from './features/sort';
import StatusLogFeature from './features/status_log';
import UploadButtonFeature from './features/upload_button';

export default class ImageGrid extends UIElementWithFeatures {
  constructor (props) {
    super(props);
    const { wrapper, options } = props;
    this.wrapper = wrapper;
    this.innerWrapper = $(wrapper).children('.modoui_image_grid_inner_wrapper')[0];
    this.options = $.extend(true, defaultOptions, options);
    this.container = $(this.wrapper).find(this.options.containerSelector)[0];
    this.canEdit = this.container.hasAttribute('data-can-edit');

    this.batchPresignUrl = this.container.getAttribute('data-presign-url');
    this.options.maxImageCount = parseInt(this.container.getAttribute('data-max-count'));
    this.options.maxPresignCount = parseInt(this.container.getAttribute('data-max-presign-count'));

    // only requests presigns if user has edit rights
    if (this.canEdit) {
      this.presignProvider = new PresignProvider({
        url: this.batchPresignUrl,
        threshold: this.options.maxPresignCount
      });
    }

    this.applyContainerStyle();

    this.toolbar = new ImageGridToolbar({ instance: this });
    this.connection = new ImageGridConnection({ instance: this });

    this.loadingImages = [];
    this.gridImages = {};

    this.features = {
      counter: CounterFeature,
      emptyGridView: EmptyGridViewFeature,
      dragAndDrop: DragAndDropFeature,
      sort: SortFeature,
      select: SelectFeature,
      delete: DeleteFeature,
      uploadButton: UploadButtonFeature,
      statusLog: StatusLogFeature
    };

    this.initFeatures();
    this.handleTurbolinksCaching();
  }

  handleTurbolinksCaching () {
    $(document).on('turbolinks:before-cache', () => $('.modoui_image_grid').children().remove());
  }

  applyContainerStyle () {
    const style = this.options.containerStyle;
    if (style.scroll) {
      if (style.resizable) {
        this.container.classList.add('resizable');
      } else {
        $(this.container).css('height', style.height);
      }
    } else if (!style.scroll) {
      $(this.container).css('min-height', style.height);
    }
    $(this.container).css('width', style.width);
  }

  addEventListener (eventName, callback, useCapture = false) {
    this.container.addEventListener(eventName, callback, useCapture);
  }

  dispatchContainerEvent (event) {
    this.container.dispatchEvent(event);
  }

  dispatchGridUpdateEvent (grid) {
    this.container.dispatchEvent(new CustomEvent('gridUpdate', { detail: { grid }, bubbles: false }));
  }

  dispatchLogEvent ({ type, summary, items }) {
    const data = { detail: { type, summary, items }, bubbles: false };
    this.dispatchContainerEvent(new CustomEvent('imageGridLogEntry', data));
  }

  dispatchLogClear () {
    this.dispatchContainerEvent(new CustomEvent('imageGridLogClear'));
  }

  dispatchUploadResolve () {
    this.dispatchContainerEvent(new CustomEvent('imageUploadResolve'));
  }

  visibleImages () {
    return $(this.container).children(':not(.image_grid_element_hidden)');
  }

  receivedImages () {
    return $(this.container).children('.image_grid_received');
  }

  updateGrid (data) {
    this.deleteIfMissing(data);
    data.forEach(image => {
      if (this.gridImages[image.id]) {
      // image is in the old grid => update
        this.gridImages[image.id].update(image);
      } else {
      // image is not in the old grid => new
        let loadingImage = null;
        if (this.loadingImages.length > 0) {
          loadingImage = this.loadingImages.pop();
        }
        this.gridImages[image.id] = new ReceivedImage({
          grid: this,
          parent: this.container,
          options: this.options,
          data: image,
          loadingImage
        });
      }
    });

    this.dispatchGridUpdateEvent(this.gridImages);
  }

  deleteIfMissing (data) {
    const keys = new Set();
    data.forEach(image => keys.add(image.id.toString()));
    // delete ones that don't exist in the new grid
    Object.keys(this.gridImages).forEach(id => {
      if (!keys.has(id)) {
        // image id is not in the new grid => delete
        if (this.container.contains(this.gridImages[id].container)) {
          this.gridImages[id].unmount();
        }
        delete this.gridImages[id];
      }
    });
  }

  computeFutureImageCount (incomingCount) {
    return Object.keys(this.gridImages).length + $('.image_grid_element .loading').length + incomingCount;
  }

  exceedingMaximumFileCount (files) {
    const totalCountAfterUploading = this.computeFutureImageCount(files.length);

    if (totalCountAfterUploading > this.options.maxImageCount) {
      const summary = I18n.t('image_grid.errors.limit', { limit: this.options.maxImageCount });
      this.dispatchLogEvent({ type: 'alarm', summary });
      this.dispatchUploadResolve();
      return true;
    }
  }

  incorrectFileType (file) {
    if (this.options.allowedImageTypes.indexOf(file.type) === -1) {
      this.errors.push(I18n.t('image_grid.errors.file_type', { filename: file.name }));
      return true;
    }
  }

  incorrectFileSize (file) {
    if (this.options.maxFileSize <= file.size) {
      const options = { filename: file.name, max_file_size: this.options.maxFileSizeString };
      this.errors.push(I18n.t('image_grid.errors.file_size', options));
      return true;
    }
  }

  createLogEntryForUploadResult ({ successful, failed, total, errors = [] }) {
    if (successful > 0) {
      const summary = I18n.t('image_grid.upload_result.successful', { file_count: successful, count: total });
      this.dispatchLogEvent({ type: 'success', summary });
    }
    if (failed > 0) {
      const summary = I18n.t('image_grid.upload_result.failed', { count: failed });
      // Add a link to the support center article to the end of errors.
      this.dispatchLogEvent({ type: 'alarm', summary, items: errors });
    }
    this.dispatchUploadResolve();
  }

  processFiles (files) {
    const images = [];
    this.errors = [];
    this.currentUploadLength = files.length;

    if (this.exceedingMaximumFileCount(files)) return;

    this.dispatchLogClear();

    this.presignProvider.get(files.length)
      .catch(() => {
        this.errors.push(I18n.t('image_grid.errors.other'));
        return [];
      })
      .then(presigns => {
        // short circuit when presigns have failed
        if (presigns.length === 0) {
          this.batchUpload(images);
          return;
        }
        // start creating the images
        ([...files]).forEach((file, key) => {
          // if incorrect filetype ignore
          if (this.incorrectFileType(file) || this.incorrectFileSize(file)) return;

          const currentLoadingImage = new LoadingImage({
            parent: this.container,
            grid: this,
            options: this.options
          });

          this.loadingImages.push(currentLoadingImage);

          // add it to the batch upload list
          const imageInstance = new UploadImage({
            grid: this,
            parent: this.container,
            file,
            presignData: presigns[key],
            options: this.options,
            loadingImage: currentLoadingImage
          });

          images.push(imageInstance.processFile());
        });

        this.batchUpload(images);
      });
  }

  batchUpload (images) {
    let files = {};
    let successResponseLength = 0;

    Promise.all(images)
      // send the information to the server
      .then(imageDataArray => {
        imageDataArray.forEach((data, index) => {
          // ignore the entry if an error occured
          if (data === UPLOAD_IMAGE_ERROR) return;
          files[index] = data;
        });

        Rails.ajax({
          type: 'post',
          url: this.options.uploadURL,
          beforeSend: (xhr, options) => {
            xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
            options.data = JSON.stringify({ files });
            return true;
          },
          success: (response) => {
            successResponseLength = response.length;

            response.forEach(errorResponse => {
              errorResponse.errors.forEach(message => {
                this.errors.push(`${errorResponse.filename} ${message}`);
              });
              this.loadingImages.pop().unmount();
            });
          },
          error: () => {
            files = {};
            this.errors.push(I18n.t('image_grid.errors.other'));
            return [];
          },
          complete: () => {
            this.createLogEntryForUploadResult({
              successful: Object.keys(files).length - successResponseLength,
              failed: this.currentUploadLength - Object.keys(files).length,
              total: this.currentUploadLength,
              errors: this.errors
            });
          }
        });
      });
  }
}
