import AbstractImage from './abstract_image';

export const UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR';

export class UploadImage extends AbstractImage {
  constructor (props) {
    super(props);
    this.uploadFile = props.file;
    this.presignData = props.presignData;
    this.loadingImage = props.loadingImage;
  }

  displayErrorAndUnmount (message) {
    this.loadingImage.unmount();
    this.grid.errors.push(message);
    return UPLOAD_IMAGE_ERROR;
  }

  processFile () {
    return this.checkForDimensions()
      .catch(() => {
        const { min, max } = this.options.imageDimensions.width;
        const message = I18n.t('image_grid.errors.image_dimension', { filename: this.uploadFile.name, min, max });
        return this.displayErrorAndUnmount(message);
      })
      .then(this.sendImageToS3.bind(this))
      .catch(() => this.displayErrorAndUnmount(`${this.uploadFile.name} could not be uploaded`))
      .then(this.createImageData.bind(this));
  }

  incorrectDimensions (imageWidth, imageHeight) {
    const { width, height } = this.options.imageDimensions;
    const incorrectWidth = width.min > imageWidth || width.max < imageWidth;
    const incorrectHeight = height.min > imageHeight || height.max < imageHeight;
    return incorrectWidth || incorrectHeight;
  }

  checkForDimensions () {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        if (this.incorrectDimensions(image.width, image.height)) {
          // eslint requires to pass in an error to reject
          reject(new Error('incorrect dimensions'));
        } else {
          resolve();
        }
      };
      image.src = window.URL.createObjectURL(this.uploadFile);
    });
  }

  sendImageToS3 (error) {
    // propagate the error
    if (error === UPLOAD_IMAGE_ERROR) return Promise.resolve(error);

    // display the placeholder
    this.loadingImage.mount();
    this.loadingImage.loadImage(this.uploadFile);

    return new Promise((resolve, reject) => {
      $.post({
        xhr: () => {
          const xhr = new window.XMLHttpRequest();
          xhr.upload.addEventListener('progress', event => {
            const { lengthComputable, loaded, total } = event;
            if (lengthComputable) {
              this.loadingImage.setProgressBar((loaded / total) * this.loadingImage.loadingSplitPercentage);
            }
            if ((loaded / total) === 1) {
              this.loadingImage.triggerAutoComplete(this.loadingImage.loadingSplitPercentage);
            }
          }, false);
          return xhr;
        },
        url: this.presignData.url,
        processData: false,
        contentType: false,
        data: this.createS3FormData()
      }).then(resolve, reject);
    });
  }

  createS3FormData () {
    const fd = new FormData();
    Object.entries(this.presignData.fields).forEach(([key, value]) => {
      fd.append(key, value);
    });
    fd.append('file', this.uploadFile);
    return fd;
  }

  createImageData (error) {
    // propagate the error
    if (error === UPLOAD_IMAGE_ERROR) return Promise.resolve(error);

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => {
        const groups = this.presignData.fields.key.match(/(^.*?(?=\/))\/(.*)/);
        const fileObj = {
          id: groups[2],
          storage: groups[1],
          metadata: {
            size: this.uploadFile.size,
            mime_type: this.uploadFile.type,
            filename: this.uploadFile.name,
            width: image.width,
            height: image.height
          }
        };
        resolve(fileObj);
      };
      image.src = window.URL.createObjectURL(this.uploadFile);
    });
  }
}
