/* global adminBanners */

$(document).on('turbolinks:load', function () {
  if ($('.modoui_banner_container').length > 0) {
    // Request parameters is an object with contextType and contextUuid
    // callback is the handler to receive an array of banner objects

    const bannerRequest = function (requestParameters, callback) {
      $.getJSON('/admin_banners', requestParameters, callback);
    };

    const dismissalCallback = function (banner) {
      Rails.ajax({
        dataType: 'json',
        type: 'POST',
        url: '/admin_banners/' + banner.attributes.uuid + '/dismissals'
      });
    };

    adminBanners(bannerRequest, dismissalCallback);
  };
});
