import Feature from './feature';

export default class CounterFeature extends Feature {
  constructor (props) {
    super(props);
    this.name = 'counterFeature';
    this.limit = this.instance.options.maxImageCount;
    this.mount();
    this.handleTurbolinksCaching();
  }

  mount () {
    this.container = document.createElement('div');
    this.container.classList.add('image_grid_counter');
    ['gridUpdate', 'imageDeleteStart', 'imageDeleteFail'].map(event => {
      this.instance.addEventListener(event, this.updateCounter.bind(this));
    });
    this.instance.wrapper.insertBefore(this.container, this.toolbar.container);
    this.updateCounter();
  }

  updateCounter () {
    const count = this.instance.receivedImages().length;
    const params = { current: count.toString(), count: this.limit.toString() };
    this.container.innerHTML = I18n.t('image_grid.counter', params);
  }

  handleTurbolinksCaching () {
    $(document).on('turbolinks:before-cache', () => $(this.container).remove());
  }
}
