import consumer from '../consumer';

$(document).on('turbolinks:load', function () {
  $('.action_cable').each(function (_index, row) {
    let buildId;

    if (row.dataset.actionCableChannel === 'build_row_updates') {
      buildId = row.dataset.actionCableBuildId;

      consumer.subscriptions.create(
        {
          channel: 'BuildRowUpdatesChannel',
          build_id: buildId
        },
        {
          received: function (data) {
            row.innerHTML = data.new_row;
            $(row).find('time.timeago').timeago();
          }
        }
      );
    }
  });
});
