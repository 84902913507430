export default class UIElementWithFeatures {
  initFeatures () {
    Object.keys(this.features).forEach(feature => {
      if (this.canUseThisFeature(feature)) {
        this.use(this.features[feature]);
      }
    });
  }

  use (FeatureClass) {
    if (FeatureClass) {
      const newFeature = new FeatureClass({ instance: this });
      this[newFeature.name] = newFeature;
    }
  }

  canUseThisFeature (feature) {
    const isEditFeature = this.options.features[feature].edit;
    return this.options.features[feature].use && (!isEditFeature || (this.canEdit && isEditFeature));
  }
}
