$(document).on('turbolinks:load', function () {
  /**
     * JS for Spectrum colorpicker
     * functions and instantiations
     **/
  $('#native_app_theme_launch_foreground_color').spectrum({
    color: $('#native_app_theme_launch_foreground_color').val(),
    allowEmpty: false,
    showInput: true,
    showAlpha: true,
    preferredFormat: 'hex8',
    containerClassName: 'modoui_form_value'
  });
  $('#native_app_theme_launch_background_color').spectrum({
    color: $('#native_app_theme_launch_background_color').val(),
    allowEmpty: false,
    showInput: true,
    showAlpha: false, // for BG colors, alphas are not allowed
    preferredFormat: 'hex6',
    containerClassName: 'modoui_form_value'
  });

  /**
     * you can also instantiate options via jquery $.fn as in:
     * $.fn.spectrum.defaults.showInput=true; or options as in
     * $.fn.spectrum.option.color='#ffffff';

     * displays the colorpicker input selection
     **/
  $('#native_app_theme_launch_background_color,#native_app_theme_launch_foreground_color').show();

  /**
     * fire events at beginning to initialze
     **/
  $('#native_app_theme_launch_background_color').trigger('change');
  $('#native_app_theme_launch_foreground_color').trigger('change');
});

$(document).on('turbolinks:before-cache', function () {
  // these objs are cached, destroy these and refresh for onload
  if ($('#native_app_theme_launch_background_color').spectrum().length) {
    $('#native_app_theme_launch_background_color').spectrum('destroy');
  }
  if ($('#native_app_theme_launch_foreground_color').spectrum().length) {
    $('#native_app_theme_launch_foreground_color').spectrum('destroy');
  }
});
