(function () {
  $(document).on('turbolinks:load', function () {
    $(document).on('click', '[data-form-submit]', function (e) {
      e.preventDefault();
      const formId = $(this).data('form-submit');

      if (formId) {
        const form = document.getElementById(formId);

        if (form.getAttribute('data-remote') === 'true') {
          Rails.fire(form, 'submit');
        } else {
          form.submit();
        }
      }
    });

    $(document).on('click', '[data-form-reset]', function (e) {
      const formId = $(this).data('form-reset');

      if (formId) {
        const form = $('form#' + formId);
        form[0].reset();
        form.find('.modoui_form_error_message').remove();
        form.find('.modoui_form_error').removeClass('modoui_form_error');

        const invalidModal = form.closest('.modal');
        invalidModal.find('.modoui_form_error').removeClass('modoui_form_error');
        invalidModal.find('.modoui_banner_container').remove();
      }
    });

    $(document).on('ajax:complete', '.modal .modoui_form', function (e) {
      const data = e.detail[0];

      if (data.responseText === 'success') {
        return location.reload();
      }

      const newModal = $($.parseHTML(data.responseText));
      const newModalContent = newModal.find('.modal-content');

      const modalContent = $(this).closest('.modal-content');
      modalContent.html(newModalContent.html());

      attachKeyboardTrap(modalContent.closest('.modal'));
      overrideCss(modalContent);
    });

    $(document).on('show.bs.modal', '.modal', function (e) {
      overrideCss($(this).find('.modal-content'));
    });

    $(document).on('shown.bs.modal', '.modal', function (e) {
      $(this).attr('data-loaded', 'true');
      attachKeyboardTrap($(this));
    });

    const invalidModal = $('.modal .modoui_form_error').first().closest('.modal');

    if (invalidModal.length) {
      invalidModal.removeClass('fade');
      invalidModal.modal('show');
      invalidModal.addClass('fade');

      $('.modal-backdrop.show').addClass('fade');
    }

    if ($('#native_app_theme_launch_background_splash').length) {
      $('#native_app_theme_launch_background_splash').on('change', function () {
        $('#native_app_theme_launch_remove_background').prop('checked', false);
      });
    }
  });

  var attachKeyboardTrap = function (modal) {
    modal.attr('tabindex', -1);

    const firstTabStop = selectFirstTabStop(modal);
    const lastTabStop = modal.find('.modal-footer .modoui_btn').last();

    attachShiftTabEvent(firstTabStop, lastTabStop);
    attachTabEvent(lastTabStop, modal);
  };

  var attachShiftTabEvent = function (firstTabStop, lastTabStop) {
    firstTabStop.on('keydown', function (e) {
      let focused = $(this).is(':focus');

      // radio button groups need special handling
      if (
        firstTabStop.is('input[type=radio]') &&
        anyRadioButtonFocused(firstTabStop.attr('name'))
      ) { focused = true; }

      if (e.keyCode === 9 && e.shiftKey && focused) {
        e.preventDefault();
        lastTabStop.focus();
      }
    });
  };

  var attachTabEvent = function (lastTabStop, modal) {
    lastTabStop.on('keydown', function (e) {
      const focused = $(this).is(':focus');

      if (e.keyCode === 9 && !e.shiftKey && focused) {
        modal.focus();
      }
    });
  };

  var selectFirstTabStop = function (modal) {
    const flashBanner = modal.find('.modoui_banner_container');
    const modalBody = modal.find('.modal-body');
    const modalFooter = modal.find('.modal-footer');
    const inputs = modalBody.find(':input:visible');
    const buttons = modalBody.find('.modoui_btn');

    let firstTabStop;

    if (flashBanner.length) {
      firstTabStop = flashBanner.find('.close');
      flashBanner.focus();
    } else if (inputs.length) {
      firstTabStop = firstTabStopFromInputs(inputs);
    } else if (buttons.length) {
      firstTabStop = buttons.first();
    } else {
      firstTabStop = modalFooter.find('.modoui_btn').first();
    }

    return firstTabStop;
  };

  var overrideCss = function (modalContent) {
    const overrideCss = { display: 'block', 'text-align': 'left' };
    const form = modalContent.find('form');

    if (form.length) {
      form.css(overrideCss);
    }
    modalContent.css(overrideCss);
  };

  var anyRadioButtonFocused = function (groupName) {
    return $('input[name="' + groupName + '"]:checked').length;
  };

  var firstTabStopFromInputs = function (inputs) {
    const firstInput = inputs.first();
    const isRadio = firstInput.is('input[type=radio]');

    if (isRadio) return $('input[name="' + firstInput.attr('name') + '"]');
    return inputs.first();
  };
}).call(this);
