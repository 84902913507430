export default {
  initDevice (element) {
    this.colWrapper = $('.modoui_device_preview_wrapper');
    this.container = $('#modoui_device_preview_item_wrapper', element);
    this.insideDeviceFrame = $('#modoui_device_preview_frame', element);
    this.deviceFrame = $('#modoui_device_preview_item', element);

    this.scalingMessage = $('.modoui_scaling_message', element);
    this.loadingWrapper = $('#loading-wrapper', element);
    this.loadingMessage = $('#loading', element);
    this.deviceWidth = '';
    this.deviceHeight = '';
    this.deviceType = 'phone';
    this.deviceOrientation = 'portrait';
    this.frameInitialized = false;
    this.tabletOffsetY = 60;
    this.tabletOffsetX = 170;

    this.frameInit(element);
  },
  frameInit (element) {
    const frameWindow = this.colWrapper;
    this.container.on('load', function () {
      const frameDocument = $(frameWindow);

      frameDocument.css({
        position: 'fixed',
        '-webkit-transform': 'translate3d(0,0,0)',
        '-moz-transform': 'translate3d(0,0,0)',
        transform: 'translate3d(0,0,0)'
      });
    });
    this.scaleFrame();
    this.frameInitialized = true;
  },
  rotateDeviceToggle (evt) {
    this.deviceOrientation = (this.deviceOrientation === 'landscape') ? 'portrait' : 'landscape';
    if (this.deviceOrientation === 'landscape') {
      this.deviceFrame.replaceClass('view_portrait', 'view_landscape');
    } else {
      this.deviceFrame.replaceClass('view_landscape', 'view_portrait');
    }

    this.deviceType = (this.deviceType === 'tablet') ? 'tablet' : 'phone';
    this.scalingMessage.toggleClass(('d-visible'), (this.deviceOrientation === 'landscape'));

    this.refreshDeviceFrameContent();
    this.onResize();
  },
  switchDevice (oSelectControl) {
    $(oSelectControl).addClass('modoui_selected').siblings().removeClass('modoui_selected');

    this.deviceType = $(oSelectControl).text().toLowerCase();
    this.deviceType = (this.deviceType === 'tablet') ? 'tablet' : 'phone';
    if (this.deviceType === 'tablet') {
      this.deviceFrame.replaceClass(
        'modoui_device_preview_item_phone',
        'modoui_device_preview_item_tablet',
        (this.deviceType === 'tablet')
      );
    } else {
      this.deviceFrame.replaceClass(
        'modoui_device_preview_item_tablet',
        'modoui_device_preview_item_phone',
        (this.deviceType === 'phone')
      );
    }

    this.refreshDeviceFrameContent();
    this.onResize();
  },
  refreshDeviceFrameContent () {
    this.deviceFrame.hide().show(0);
  },
  onResize () {
    this.scaleFrame();
  },
  scaleFrame () {
    const viewportScale = 1;

    if (this.deviceFrame) {
      this.rescaleFrame(1);

      const container = this.container;
      const containerWidth = container.innerWidth();
      const containerHeight = container.innerHeight();
      const deviceWidth = this.deviceFrame.outerWidth();
      const deviceHeight = this.deviceFrame.outerHeight();

      const viewportToDeviceHeightRatio = containerHeight / deviceHeight;
      const viewportToDeviceWidthRatio = containerWidth / deviceWidth;

      if (viewportToDeviceHeightRatio < 1 || viewportToDeviceWidthRatio < 1) {
        if (viewportToDeviceHeightRatio < viewportToDeviceWidthRatio) {
          this.rescaleFrame(viewportToDeviceHeightRatio);
        } else {
          this.rescaleFrame(viewportToDeviceWidthRatio);
        }
      }

      const marginLeft = Math.round((containerWidth - this.deviceFrame.outerWidth()) / 2);
      let marginTop;

      if (this.deviceType === 'tablet' && this.deviceOrientation === 'portrait') {
        marginTop = Math.round((containerWidth - (this.deviceFrame.outerWidth() + this.tabletOffsetY)) / 2);
        this.deviceFrame.css('margin-top', marginTop);
        this.scalingMessage.css('top', marginTop);
        this.loadingWrapper.css('margin-bottom', '0px');
      } else if (this.deviceType === 'tablet' && this.deviceOrientation === 'landscape') {
        marginTop = Math.round((containerHeight - (this.deviceFrame.outerHeight() + this.tabletOffsetX)));
        this.deviceFrame.css('margin-top', marginTop);
        this.scalingMessage.css('top', marginTop + (-20));
        this.loadingWrapper.css('margin-bottom', '0px');
      } else {
        this.deviceFrame.css('margin-top', '20px');
        this.scalingMessage.css('top', '20px');
        this.loadingWrapper.css('margin-bottom', '0px');
      }

      if (this.deviceType === 'phone' && this.deviceOrientation === 'landscape') {
        marginTop = Math.round((containerHeight - (this.deviceFrame.outerHeight())));
        this.scalingMessage.css('top', '-20px');
        this.loadingWrapper.css('margin-bottom', '0px');
      }

      this.deviceFrame.css('margin-left', marginLeft);
    }
    return viewportScale;
  },
  rescaleFrame (viewportScale) {
    this.deviceFrame.css({
      '-moz-transform': 'scale(' + viewportScale + ')',
      '-webkit-transform': 'scale(' + viewportScale + ')',
      transform: 'scale(' + viewportScale + ')'
    });
    $('.modoui_scaling_message')
      .text('Scaled to ' + Math.round(viewportScale * 100) + '%')
      .toggleClass('d-visible', viewportScale !== 1)
      .width(this.deviceWidth);
    $('#loading-wrapper')
      .width(this.deviceWidth)
      .height('40px');
  }
};

(function ($) {
  $.fn.replaceClass = function (pRemoveClass, pAddClass) {
    return this.removeClass(pRemoveClass).addClass(pAddClass);
  };
}($));
